/*
 * The slideshow is a scroll/touch helper to present a lightweight swipable image slideshow.
 * It responds to swipe and scroll geustures but not browser drag.
 * The basic principle:
 * 1. transform with gesture
 * 2. work out intent based on size of gesture
 * 3. snap to complete or revert the transition
 * Much of this is taken from Kevin Foley
 * at https://css-tricks.com/the-javascript-behind-touch-friendly-sliders/
 */

class Slideshow {
  constructor(el, callbacks) {
    this.el = el;
    this.callbacks = callbacks || {};
    this.touchstartx = undefined;
    this.touchmovex = undefined;
    this.movex = undefined;
    this.index = 0;
    this.max = 0;
    this.maxDrag = 0;
    this.longTouch = undefined;
    this.mode = 'horizontal';

    this.init = this.init.bind(this);
    this.getSlides = this.getSlides.bind(this);
    this.movePrev = this.movePrev.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.panTo = this.panTo.bind(this);
    this.setAspect = this.setAspect.bind(this);

    const resizeObserver = new ResizeObserver(this.setAspect);
    resizeObserver.observe(this.el);
  }

  init() {
    this.holder = this.el.querySelector('.cms-slide-holder');
    this.slides = this.el.querySelectorAll('.cms-slide');
    this.next = this.el.querySelector('a.next');
    this.prev = this.el.querySelector('a.prev');
    this.setAspect();
    this.getSlides();
    if (this.max > 0) {
      this.holder.addEventListener('touchstart', this.touchStart.bind(this));
      this.holder.addEventListener('touchmove', this.touchMove.bind(this));
      this.holder.addEventListener('touchend', this.touchEnd.bind(this));
      this.next.addEventListener('click', this.moveNext.bind(this));
      this.prev.addEventListener('click', this.movePrev.bind(this));
      this.el.classList.add('shimmy');
    } else {
      if (this.next) this.next.remove();
      if (this.prev) this.prev.remove();
    }
  }

  getSlides() {
    this.slides = this.el.querySelectorAll('.cms-slide');
    this.max = this.slides.length - 1;
    if (this.slideWidth) {
      this.maxDrag = this.max * this.slideWidth;
    }
  }

  setAspect() {
    const rect = this.el.getBoundingClientRect();
    const aspect = rect.width / rect.height;
    if (aspect < 0.4) {
      this.mode = 'vertical';
      this.el.parentElement.classList.add('vertical');
    } else if (this.mode === 'vertical') {
      this.mode = 'horizontal';
      this.el.parentElement.classList.remove('vertical');
    }
    this.slideWidth = rect.width;
    this.maxDrag = this.max * this.slideWidth;
  }

  movePrev() {
    if (this.index === 0) this.panTo(this.max)
    else this.panTo(this.index - 1);
  }

  moveNext() {
    if (this.index === this.max) this.panTo(1)
    else this.panTo(this.index + 1);
  }

  moveToEndWhenUpdated() {
    this._goToEndOnUpdate = true;
  }

  panTo(index) {
    if (index >= 0 && index <= this.max) {
      this.holder.classList.add('animate')
      this.holder.style.transform = `translate3d(-${index * this.slideWidth}px,0,0)`;
      this.index = index;
      if (this.callbacks.onMove) this.callbacks.onMove(this.index);
    }
  }

  revert() {
    this.panTo(this.index);
  }

  reset() {
    this.panTo(0);
  }

  // Touch event handlers
  //
  touchStart(event) {
    this.longTouch = false;
    const flickCheck = () => { this.longTouch = true; };
    setTimeout(flickCheck.bind(this), 250);
    this.touchstartx = event.touches[0].pageX;
     // don't transition while dragging
    document.querySelectorAll('.animate').forEach((el) => {
      el.removeClass('animate');
    });
  }

  touchMove(event) {
    this.touchmovex = event.touches[0].pageX;
    this.movex = this.index * this.slideWidth + (this.touchstartx - this.touchmovex);
    if (this.movex < 0) {
      this.movex = -(2 * Math.sqrt(Math.abs(this.movex)));
    } else if (this.movex > this.maxDrag) {
      this.movex = this.maxDrag + (2 * Math.sqrt(Math.abs(this.movex - this.maxDrag)));
    }
    this.holder.style.transform = `translate3d(${-this.movex}px,0,0)`;
  }

  touchEnd() {
    // Get size of swipe
    const absMove = Math.abs(this.index * this.slideWidth - this.movex);

    // Check for sufficient movement:
    // dragged more than halfway,      or a quick flick
    const significantMove = (absMove > (this.slideWidth / 3) || this.longTouch === false);

    // Establish direction if any
    let direction;
    if (this.movex > (this.index * this.slideWidth)) direction = 'right';
    else if (this.movex < (this.index * this.slideWidth)) direction = 'left';
    else direction = 'unmoved';

    // move to specified slide if there is one, or rebound to current slide
    if (significantMove && direction === 'right' && this.index < this.max) {
      this.moveNext();
    } else if (significantMove && direction === 'left' && this.index > 0) {
      this.movePrev();
    } else {
      this.revert();
    }
  }
}

// repeatable init function is the only export;
// returns the slideshow object.
//
function slideshow(el, callbacks) {
  if (el.slideshow) {
    el.slideshow.refresh();
  } else {
    // eslint-disable-next-line no-param-reassign
    el.slideshow = new Slideshow(el, callbacks);
    el.slideshow.init();
  }
  return el.slideshow;
}

export default slideshow;
